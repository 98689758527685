<template>
    <div
        :class="{
            'legend-is-hovering': legendIsHovering
        }">
        <div
            class="legend"
            @mouseover="legendIsHovering = true"
            @mouseleave="legendIsHovering = false">
            <div class="legend-inner">
                <div class="progress-line">
                    <div
                        v-for="(group, group_index) in groups"
                        :key="group_index">
                        <div
                            v-if="groupHasSteps(group)"
                            class="layout row align-center justify-space-between pa-3"
                            :class="[group_index > 0 ? 'border-top' : '']">
                            <button
                                class=""
                                @click="group.toggleShow(group_index)">
                                <icon
                                    name="hambuger"
                                    size="15" />
                            </button>
                            <div class="layout row align-center justify-end">
                                <div class="mr-2">
                                    <img
                                        style="height:30px"
                                        :src="group.icon">
                                </div>
                                <div class="font-weight-bold mr-20">
                                    {{ group.name }}
                                </div>
                                <div class="">
                                    <div v-if="groupSomeStepsComplete(group) && !groupAllStepsComplete(group)">
                                        <div class="top-half-circle" />
                                        <div class="bottom-half-circle" />
                                    </div>
                                    <div v-if="!groupSomeStepsComplete(group) && !groupAllStepsComplete(group)">
                                        <icon
                                            name="circle"
                                            color="lightGray"
                                            size="26" />
                                    </div>
                                    <div
                                        v-if="groupAllStepsComplete(group)"
                                        style="position:relative;">
                                        <status-icon
                                            value="success"
                                            background-color="white"
                                            color="blue"
                                            size="26"
                                            class="status-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            v-for="(step, step_index) in steps"
                            :key="step_index">
                            <div
                                v-if="group.key === step.group_key && group.show"
                                class="layout row">
                                <button
                                    class="pa-2 mr-25 text-xs-right layout row justify-end align-center"
                                    :class="{
                                        'stepper': true,
                                        'stepper-active': (latest >= step_index),
                                        'stepper-current': (value == step_index),
                                        'stepper-complete': (step.complete)
                                    }"
                                    @click="(latest >= step_index) ? $emit('input', step_index) : null">
                                    <div
                                        :class="[isCurrentStep(step) ? 'font-weight-bold text-blue' : '']"
                                        class="mr-20 label">
                                        {{ step.label }}
                                    </div>
                                    <div class="">
                                        <status-icon
                                            v-if="statusIconIsActive(step, step_index)"
                                            :value="(step.required && !step.complete) ? 'warning' : 'success'"
                                            background-color="white"
                                            size="20"
                                            class="status-icon" />
                                        <span
                                            v-else
                                            class="dot" />
                                    </div>
                                    <div
                                        class="stepper-line" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!!$slots['legend-footer']"
                class="legend-footer">
                <slot name="legend-footer" />
            </div>
        </div>
        <div class="step-wrapper">
            <div
                class="step"
                :style="{
                    maxWidth,
                    padding: noPadding ? '0' : '40px'
                }">
                <keep-alive :max="20">
                    <component
                        :is="currentComponent"
                        v-if="currentComponent"
                        :key="keepAliveKey" />
                </keep-alive>
            </div>
            <div
                v-if="!!$slots['step-footer']"
                class="step-footer"
                :style="{
                    maxWidth
                }">
                <slot name="step-footer" />
            </div>
        </div>
    </div>
</template>

<script>
import StatusIcon from './StatusIcon';
import Icon from '@/components/globals/Icon';
import { groups } from '@/components/onboarding/workflow/steps.js';
export default {
    name: 'MultiStep',
    components: {
        StatusIcon,
        Icon
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
        latest: {
            type: Number,
            required: true
        },
        steps: {
            type: Array,
            required: true
        },
        statusIcons: {
            type: Boolean,
            default: false
        },
        keepAliveKey: {
            type: String,
            required: true
        },
        maxWidth: {
            type: String,
            default: '1100px'
        },
        noPadding: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            legendIsHovering: false,
            groups
        };
    },
    computed: {
        stepConfiguration() {
            return this.steps[this.value];
        },
        currentComponent() {
            return this.stepConfiguration ? this.stepConfiguration.component : null;
        }
    },
    watch: {
        value(step_index) {
            this.togglePlatformOpenByStep(this.steps[step_index]);
        }
    },
    created() {
        this.togglePlatformOpenByStep(this.steps[this.value]);
    },
    methods: {
        statusIconIsActive(step, index) {
            if (!this.statusIcons) {
                return false;
            }

            if (step.complete) {
                return true;
            }

            // If the step has been passed and it's required
            // but not complete show the status icon so we can
            // display a warning
            if (
                (this.latest > index) &&
                (step.required && !step.complete)
            ) {
                return true;
            }

            return false;
        },
        groupHasSteps(group) {
            return this.steps.some(step => step.group_key === group.key);
        },
        groupIsLastStep(group, step) {
            const steps = this.steps.filter(step => step.group_key === group.key);
            const lastStep = steps[steps.length - 1];
            return step === lastStep;
        },
        groupAllStepsComplete(group) {
            return this.steps.filter(step => step.group_key === group.key).every(step => step.complete);
        },
        groupSomeStepsComplete(group) {
            return this.steps.filter(step => step.group_key === group.key).some(step => step.complete);
        },
        isCurrentStep(step) {
            return this.steps[this.value] === step;
        },
        togglePlatformOpenByStep(step) {
            this.groups.forEach(group => {
                if (group.key === step.group_key) {
                    group.show = true;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>

.legend {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 1px solid $gray-light;
    border-left-width: 0;
    border-radius: 0 5px 5px 0;
    margin-bottom: 80px;
    transform: translateX(-242px);
    transition: transform 0.2s ease-in-out, max-height 0.2s ease-in-out;
    max-height: 2000px;
    z-index: 50;
    @media (min-width: 1550px) {
        transform: translateX(0);
    }
    .legend-inner {
        display: flex;
        width: 300px;
    }
    .progress-line {
        margin-left: auto;
        width: 100%
    }
    .stepper {
        &:focus,
        &:active {
            outline: none;
        }
        &:hover {
            cursor: default;
        }
        &.stepper-active {
            &:hover {
                cursor: pointer;
            }
            .label {
                color: $gray;
            }
        }
        &.stepper-active:not(.stepper-current) {
            &:hover {
                .label {
                    color: $blue-bg;
                }
                .status-icon {
                    transform: scale(1.2);
                }
            }
        }
        &.stepper-current {
            .dot {
                transform: scale(1.3);
                background-color: $blue-bg;
            }
            .label {
                color: $blue-bg;
                font-weight: bold;
            }
        }
        &.stepper-current.stepper-complete {
            .label {
                color: $blue-bg;
            }
        }
        &.stepper-active.stepper-complete {
            &:hover {
                .label {
                    color: $blue-bg;
                }
            }
        }
        &.stepper-complete {
            .label {
                color: $gray-light;
            }
        }
        .status-icon {
            position: relative;
            transition: transform 0.2s ease-in-out;
        }
        .label {
            color: $gray-light;
        }
    }
    .stepper-line {
        position: absolute;
        width: 1px;
        background-color: $gray;
        height: 40px;
        margin: -40px 0 0 0;
        margin-right: 10px;
        transition: background-color 0.4s ease-in-out;
        z-index: -10;
    }
}

.step-wrapper {
    padding: 0 40px 0 100px;
    margin-bottom: 80px;
    @media (min-width: 1550px) {
        padding: 0 40px 0 240px;
    }
    .step {
        background-color: $white;
        border: 1px solid $gray-light;
        margin: 0 auto;
        max-width: 900px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
    }
    .step-footer {
        padding: 5px 10px 0;
        text-align: center;
        color: $gray;
        margin: 0 auto;
        font-style: italic;
    }
}

.legend-is-hovering {
    .step {
        opacity: 0.2;
        @media (min-width: 1550px) {
            opacity: 1;
        }
    }
    .legend {
        transform: translateX(0px);
    }
}

.top-half-circle {
    width: 26px;
    height: 13px;
    border-radius: 25px 25px 0 0;
    background-color: lightgray;
}

.bottom-half-circle {
    width: 26px;
    height: 13px;
    border-radius: 25px 25px 0 0;
    background-color: #00BCFF;
    transform: rotate(180deg);
}

.dot {
    display: block;
    height: 10px;
    width: 10px;
    margin: 5px;
    border-radius: 50%;
    background-color: $gray;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
}

.text-blue {
    color: #00BCFF;
}

.border-top {
    border-top: 1px solid lightgray;
}

.mr-25 {
    margin-right: 10px;
}

.check-icon {
    position: absolute;
    top: -10px;
}

.mr-20 {
    margin-right: 20px;
}
</style>
