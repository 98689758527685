<template>
    <styled-interface
        no-padding
        no-overflow
        hide-toolbar>
        <!-- Exit dialog start -->
        <styled-dialog v-model="exitDialogOpen">
            <template #heading>
                <h3>Are you sure you want to exit?</h3>
            </template>

            <p class="mb-5">
                You or anyone on your team can resume this onboarding at
                any time from {{ dealer.name }}'s dashboard, the
                onboarding status report or the alerts menu in the navbar.
            </p>

            <div class="text-xs-center mb-4">
                <styled-button
                    @click="$store.dispatch('exitOnboarding')">
                    Yes, Exit &amp; Save
                </styled-button>
            </div>

            <a
                href="#"
                class="red--text"
                @click.prevent="exitDialogOpen = false">
                Cancel
            </a>
        </styled-dialog>
        <!-- Exit dialog end -->

        <!-- Skip dialog start -->
        <styled-dialog v-model="skipDisclaimerOpen">
            <template #heading>
                <h3>Are You Sure You Want to Skip?</h3>
            </template>

            <p class="mb-4">
                It is important to note that you can skip
                early steps that aren't critical to later steps
                but you'll still need to finish this step before
                and any others you skip before onboarding can be completed.
            </p>

            <div class="text-xs-center mb-4">
                <styled-button
                    outline
                    @click="onSkipDisclaimerApproval">
                    Yes, I Understand
                </styled-button>
            </div>

            <a
                href="#"
                class="red--text"
                @click.prevent="skipDisclaimerOpen = false">
                Cancel
            </a>
        </styled-dialog>
        <!-- Skip dialog end -->


        <onboarding-header
            :dealer="dealer"
            :show-product="true"
            :products="onboardingProducts"
            :show-play="!!play"
            :play="play"
            :step="activeStep ? activeStep.title : null">
            <template
                v-if="!loading"
                #actions>
                <action-button
                    icon="indicator-error"
                    position="left"
                    light
                    normal-text
                    @click="exitDialogOpen = true">
                    Save &amp; Exit
                </action-button>
            </template>
        </onboarding-header>

        <div
            v-if="error"
            class="error-container"
            :class="{
                'error-container-loading': loading
            }">
            <v-alert
                :value="error"
                type="error"
                transition="fade-transition">
                {{ error }}
            </v-alert>
        </div>
        <div
            v-if="loading"
            class="loading-container">
            <loader label="Initializing setup steps..." />
        </div>
        <template v-else-if="!loading && currentSteps.length">
            <multi-step
                v-model="currentStep"
                class="multi-step"
                :latest="latestStep"
                :steps="currentSteps"
                status-icons
                :keep-alive-key="currentStepKey"
                v-bind="activeMultiStepProps">
                <template #legend-footer>
                    <div class="legend-refresh">
                        <action-button
                            icon="refresh"
                            size="15"
                            position="left"
                            normal-text
                            light
                            :loading="statusLoading"
                            @click="$store.dispatch('updateOnboardingStatus')">
                            {{ statusLoading ? 'Rechecking status' : 'Recheck status' }}
                        </action-button>
                    </div>
                </template>
                <template
                    v-if="activeStep && activeStep.disclaimer"
                    #step-footer>
                    {{ activeStep.disclaimer }}
                </template>
            </multi-step>
        </template>
    </styled-interface>
</template>

<script>
import { debounce } from 'lodash';
import OnboardingHeader from '@/components/globals/OnboardingHeader';
import StyledButton from '@/components/globals/StyledButton';
import StyledDialog from '@/components/globals/StyledDialog';
import Loader from '@/components/globals/Loader';
import ActionButton from '@/components/globals/ActionButton';
import MultiStep from '@/components/globals/MultiStep';
import StyledInterface from '@/components/globals/StyledInterface';
import isEqual from '@/helpers/isEqual';
import steps from './steps';
import { mapState, mapGetters, mapActions } from 'vuex';
import {
    TOGGLE_ONBOARDING_SKIP_DISCLAIMER,
    // SET_ONBOARDING_SKIP_DISCLAIMER_APPROVAL,
    SET_ONBOARDING_CURRENT_STEP,
    // SET_ONBOARDING_LATEST_STEP,
} from '@/store/mutation-types';

export default {
    name: 'Onboarding',
    title: 'Onboarding',
    components: {
        OnboardingHeader,
        ActionButton,
        Loader,
        MultiStep,
        StyledButton,
        StyledInterface,
        StyledDialog
    },
    props: {
        onboardingId: {
            type: Number,
            required: true
        },
        stepKey: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            exitDialogOpen: false,
            supportMenuOpen: false,
            loading: false,
            onboardingSteps: steps
        };
    },
    computed: {
        ...mapState({
            latestStep: (state) => state.onboarding.latestStep,
            initializing: (state) => state.onboarding.initializing,
            dealer: (state) => state.dealer.currentDealer,
            dealerId: (state) => state.dealer.currentDealerId,
            type: (state) => state.onboarding.type,
            error: (state) => state.onboarding.error,
            product: (state) => state.onboarding.product,
            play: (state) => state.onboarding.play,
            statusLoading: (state) => state.onboarding.statusLoading,
            onboardingProducts: (state) => state.onboarding.products,
        }),
        ...mapGetters([
            'dealerProducts',
            'currentSteps',
            'onboardingKey',
            'onboardingStorageData',
        ]),
        activeMultiStepProps() {
            if (!this.activeStep) {
                return {};
            }

            return this.activeStep.multiStepProps || {};
        },
        activeStep() {
            if (!this.currentSteps.length) {
                return null;
            }
            return this.currentSteps[this.currentStep];
        },
        currentStepKey() {
            return `${this.onboardingKey}:${this.activeStep?.key}`;
        },
        skipDisclaimerOpen: {
            get() {
                return this.$store.state.onboarding.skipDisclaimerOpen;
            },
            set(isOpen) {
                this.$store.commit(TOGGLE_ONBOARDING_SKIP_DISCLAIMER, isOpen);
            }
        },
        currentStep: {
            get() {
                return this.$store.state.onboarding.currentStep;
            },
            set(step) {
                if (this.$store.state.onboarding.currentStep !== step) {
                    this.$store.commit(SET_ONBOARDING_CURRENT_STEP, step);
                }
            }
        }
    },
    watch: {
        onboardingStorageData: {
            deep: true,
            handler(data, oldData) {
                // Don't update while initializing or if the data hasn't changed
                if (this.initializing || isEqual(data, oldData)) {
                    return;
                }

                this.updateStorage(data);
            }
        },
        currentStep(step) {
            if (step === null) {
                return;
            }

            this.updateRouteQuery();
        },
        stepKey(key, oldKey) {
            if (key && key !== oldKey) {
                this.$store.dispatch('goToStepByKey', key);
            }
        },
    },
    created() {
        this.init();
    },
    methods: {
        ...mapActions([
            'updateCurrentDealer'
        ]),
        async init() {
            this.loading = true;

            await this.updateCurrentDealer();

            await this.$store.dispatch('loadOnboarding', {
                onboardingId: this.onboardingId,
                stepKey: this.stepKey,
                redirectUrl: this.$route.query?.redirect_url ?? null
            });

            // Ensure the router is synced
            if (this.$route.query.step &&
                (this.$route.query.step !== this.activeStep.key)) {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        ...{ step: this.activeStep.key }
                    }
                });
            }

            this.loading = false;
        },
        updateStorage: debounce(function(data) {

            if (!this.onboardingId) {
                return;
            }

            this.$apiRepository
                    .updateDealerProductOnboarding(this.onboardingId, data)
                    .catch(error => {
                        console.error('Error updating onboarding data', error);
                    });
        }, 500),
        updateRouteQuery() {
            if (!this.activeStep) {
                return;
            }

            if (this.$route.query.step === this.activeStep.key) {
                return;
            }

            this.$router.push({
                query: {
                    ...this.$route.query,
                    ...{ step: this.activeStep.key }
                }
            });
        },
        onSkipDisclaimerApproval() {
            this.$store.dispatch('handleSkipDisclaimerApproval');
        }
    }
};
</script>

<style lang="scss" scoped>

.legend-refresh {
    background-color: $gray;
    color: $white;
    text-align: center;
    padding: 10px 0;
    border-radius: 0 0 5px 0;
    a {
        color: $white;
        text-decoration: none;
    }
}

.error-container {
   margin: 80px 20px -60px;
   &.error-container-loading {
       margin: 20px;
   }
}

.multi-step {
    margin-top: 160px;
}

.loading-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
