var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'legend-is-hovering': _vm.legendIsHovering
    }},[_c('div',{staticClass:"legend",on:{"mouseover":function($event){_vm.legendIsHovering = true},"mouseleave":function($event){_vm.legendIsHovering = false}}},[_c('div',{staticClass:"legend-inner"},[_c('div',{staticClass:"progress-line"},_vm._l((_vm.groups),function(group,group_index){return _c('div',{key:group_index},[(_vm.groupHasSteps(group))?_c('div',{staticClass:"layout row align-center justify-space-between pa-3",class:[group_index > 0 ? 'border-top' : '']},[_c('button',{on:{"click":function($event){return group.toggleShow(group_index)}}},[_c('icon',{attrs:{"name":"hambuger","size":"15"}})],1),_c('div',{staticClass:"layout row align-center justify-end"},[_c('div',{staticClass:"mr-2"},[_c('img',{staticStyle:{"height":"30px"},attrs:{"src":group.icon}})]),_c('div',{staticClass:"font-weight-bold mr-20"},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('div',{},[(_vm.groupSomeStepsComplete(group) && !_vm.groupAllStepsComplete(group))?_c('div',[_c('div',{staticClass:"top-half-circle"}),_c('div',{staticClass:"bottom-half-circle"})]):_vm._e(),(!_vm.groupSomeStepsComplete(group) && !_vm.groupAllStepsComplete(group))?_c('div',[_c('icon',{attrs:{"name":"circle","color":"lightGray","size":"26"}})],1):_vm._e(),(_vm.groupAllStepsComplete(group))?_c('div',{staticStyle:{"position":"relative"}},[_c('status-icon',{staticClass:"status-icon",attrs:{"value":"success","background-color":"white","color":"blue","size":"26"}})],1):_vm._e()])])]):_vm._e(),_vm._l((_vm.steps),function(step,step_index){return _c('div',{key:step_index},[(group.key === step.group_key && group.show)?_c('div',{staticClass:"layout row"},[_c('button',{staticClass:"pa-2 mr-25 text-xs-right layout row justify-end align-center",class:{
                                    'stepper': true,
                                    'stepper-active': (_vm.latest >= step_index),
                                    'stepper-current': (_vm.value == step_index),
                                    'stepper-complete': (step.complete)
                                },on:{"click":function($event){(_vm.latest >= step_index) ? _vm.$emit('input', step_index) : null}}},[_c('div',{staticClass:"mr-20 label",class:[_vm.isCurrentStep(step) ? 'font-weight-bold text-blue' : '']},[_vm._v(" "+_vm._s(step.label)+" ")]),_c('div',{},[(_vm.statusIconIsActive(step, step_index))?_c('status-icon',{staticClass:"status-icon",attrs:{"value":(step.required && !step.complete) ? 'warning' : 'success',"background-color":"white","size":"20"}}):_c('span',{staticClass:"dot"})],1),_c('div',{staticClass:"stepper-line"})])]):_vm._e()])})],2)}),0)]),(!!_vm.$slots['legend-footer'])?_c('div',{staticClass:"legend-footer"},[_vm._t("legend-footer")],2):_vm._e()]),_c('div',{staticClass:"step-wrapper"},[_c('div',{staticClass:"step",style:({
                maxWidth: _vm.maxWidth,
                padding: _vm.noPadding ? '0' : '40px'
            })},[_c('keep-alive',{attrs:{"max":20}},[(_vm.currentComponent)?_c(_vm.currentComponent,{key:_vm.keepAliveKey,tag:"component"}):_vm._e()],1)],1),(!!_vm.$slots['step-footer'])?_c('div',{staticClass:"step-footer",style:({
                maxWidth: _vm.maxWidth
            })},[_vm._t("step-footer")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }